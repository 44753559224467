import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import myToaster from "../../utils/toaster";
import BreadcrumbComponent from "../shared/Breadcrumb";
import InputField from "../public/InputField";
import Spin from "../public/Spin";
import { addLeadSource, leadSources } from "../../Services/LeadSource";
import logoSide from "../../utils/paid-idea-concept-illustration_114360-8185.jpg"
const LeadSource = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  let user =JSON.parse(localStorage.getItem("user"));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await addLeadSource(data);
      if (response.isSuccess) {
        myToaster.showSuccessToast(response.message);
        if(user.userRole==3){
          navigate("/salesExecutive/leadSourceList"); 
        }
        else if(user.userRole==4){
          navigate("/salesManager/leadSourceList"); 
        }
        else{
          myToaster.showErrorToast("UnAuthorized User please login",response.message)
        }
      } else {
        myToaster.showErrorToast(response.message);
      }
    } catch (error) {
      myToaster.showErrorToast("Failed to add lead source. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BreadcrumbComponent
        labels={{
          module: "salesExecutive",
          currentRoute: "Register-New-LeadSource",
        }}
      />
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          width: "100%",
          minHeight: "100vh", // Ensures the container takes up the full viewport height
          padding: "20px",
        }}
      >
        <div style={{ flex: 1, maxWidth: "600px", padding: "20px" }}>
          <div className="col-lg-12 mb-4-lg-0">
            <div
              className="login-container"
              style={{
                background: "#fff",
                padding: "35px",
                borderRadius: "10px",
                boxShadow: "0 0 15px rgba(0,0,0,0.1)",
                width: "80%",
              }}
            >
              <h2 className="form-title" style={{ textAlign: "center", marginBottom: "20px" }}>
                Register New Lead Source
              </h2>
              <form
                className="login-form"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <div className="row">
                  {/* Lead Source Name Field */}
                  <div className="col-lg-12 mb-3">
                    <InputField
                      type="text"
                      name="leadSourceName"
                      style={{
                        padding: "0px 1.25rem 0 1.12rem",
                        width: "100%",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      placeholder="Lead Source Name"
                      {...register("leadSourceName", {
                        required: "Lead Source Name is required",
                      })}
                    />
                    {errors.leadSourceName && (
                      <span className="error-message" style={{ color: "red" }}>
                        {errors.leadSourceName.message}
                      </span>
                    )}
                  </div>
  
                  {/* Description Field */}
                  <div className="col-lg-12 mb-3">
                    <textarea
                      name="description"
                      rows={6}
                      style={{
                        padding: "0px 1.25rem 0 1.12rem",
                        width: "100%",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      placeholder="Description"
                      {...register("description", {
                      })}
                    />
                    {errors.description && (
                      <span className="error-message">
                        {errors.description.message}
                      </span>
                    )}
                  </div>
                </div>
  
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ width: "100%" }}
                  disabled={loading}
                >
                  {loading ? <Spin /> : "Register Lead Source"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  
};

export default LeadSource;
